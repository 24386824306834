import { RegistryTenant } from "./registry-tenants";

export interface RegistryLink {
    text: string;
    link: string;
    registry?: string;
}

export interface RegistryLinkMap {
    [registry: string]: Array<RegistryLink | undefined>;
}

export function getTenantLink(tenant: RegistryTenant): RegistryLink | undefined {
    switch (tenant) {
        case "dica":
            return { text: "DICA", link: "https://dica.nl/" };
        case "dicawgz":
            return {
                text: "DICAWGZ",
                link: "https://www.saz-ziekenhuizen.nl/thema/waardegedreven-zorg/",
            };
        case "niv":
            return { text: "NIV", link: "https://www.internisten.nl/" };
        case "pice":
            return { text: "PICE", link: "https://pice.nl/" };
        case "rivm":
            return { text: "RIVM", link: "https://www.rivm.nl/" };
        case "swab":
            return { text: "SWAB", link: "https://swab.nl/nl" };
        default:
            return undefined;
    }
}

const DICA_BASE = "https://dica.nl/registratie/";

export const registryLinks: RegistryLinkMap = {
    AmsmReporting: [
        {
            text: "AMSM",
            link: "https://swab.nl/nl/antimicrobial-stewardshipmonitor",
        },
        //getTenantLink("swab"),
    ],
    Daci: [
        {
            text: "DACI",
            link: `${DICA_BASE}halsslagaderinterventies-daci`,
        },
        //getTenantLink("dica"),
    ],
    Dasa: [
        {
            text: "DASA",
            link: `${DICA_BASE}beroertezorg-dasa/`,
        },
        //getTenantLink("dica"),
    ],
    Dato: [
        {
            text: "DATO",
            link: `${DICA_BASE}obesitas-dato/`,
        },
        //getTenantLink("dica"),
    ],
    Dbir: [
        {
            text: "DBIR",
            link: `${DICA_BASE}borstimplantaten-dbir/`,
        },
        //getTenantLink("dica"),
    ],
    Dcra: [
        {
            text: "DCRA",
            link: `${DICA_BASE}darmkanker-dcra/`,
        },
        //getTenantLink("dica"),
    ],
    DcraR: [
        {
            text: "DCRA",
            link: `${DICA_BASE}darmkanker-dcra/`,
        },
        //getTenantLink("dica"),
    ],
    DcraWgz: [
        {
            text: "DCRA",
            link: `${DICA_BASE}darmkanker-dcra/`,
        },
        //getTenantLink("dicawgz"),
    ],
    Demo: [
        {
            text: "DEMO",
            link: "https://www.internisten.nl/werken-als-internist/richtlijnenindicatoren/kwaliteitsregistratie-reglementen",
        },
        //getTenantLink("niv"),
    ],
    Dgda: [
        {
            text: "DGDA",
            link: `${DICA_BASE}galsteenlijsten-dgda/`,
        },
        //getTenantLink("dica"),
    ],
    DgeaDrce: [
        {
            text: "DGEA",
            link: `${DICA_BASE}kijkonderzoek-maag-darm-lever-dgea/`,
        },
        {
            text: "DRCE",
            link: `${DICA_BASE}complicaties-kijkonderzoek-maag-darm-lever-drce/`,
        },
        //getTenantLink("dica"),
    ],
    Dgoa: [
        {
            text: "DGOA",
            link: `${DICA_BASE}gynaecologische-oncologie-dgoa/`,
        },
        //getTenantLink("dica"),
    ],
    Dhba: [
        {
            text: "DHBA",
            link: `${DICA_BASE}lever-en-galwegen-dhba/`,
        },
        //getTenantLink("dica"),
    ],
    Dhfa: [
        {
            text: "DHFA",
            link: `${DICA_BASE}heupfractuur-dhfa/`,
        },
        //getTenantLink("dica"),
    ],
    DhfaWgz: [
        {
            text: "DHFA",
            link: `${DICA_BASE}heupfractuur-dhfa/`,
        },
        //getTenantLink("dicawgz"),
    ],
    Dhna: [
        {
            text: "DHNA",
            link: `${DICA_BASE}hoofd-halsoncologie-dhna/`,
        },
        //getTenantLink("dica"),
    ],
    Diha: [
        {
            text: "DIHA",
            link: `${DICA_BASE}liesbreuk-diha/`,
        },
        //getTenantLink("dica"),
    ],
    DlcaS: [
        {
            text: "DLCA",
            link: `${DICA_BASE}longkanker-dlca/`,
        },
        //getTenantLink("dica"),
    ],
    DlcaSErats: [
        {
            text: "DLCA",
            link: `${DICA_BASE}longkanker-dlca/`,
        },
        //getTenantLink("dica"),
    ],
    DlcaR: [
        {
            text: "DLCA",
            link: `${DICA_BASE}longkanker-dlca/`,
        },
        //getTenantLink("dica"),
    ],
    DlcaL: [
        {
            text: "DLCA",
            link: `${DICA_BASE}longkanker-dlca/`,
        },
        //getTenantLink("dica"),
    ],
    Dma: [
        {
            text: "DMA",
            link: `${DICA_BASE}geneesmiddelen-dma/`,
        },
        getTenantLink("dica"),
    ],
    Dmtr: [
        {
            text: "DMTR",
            link: `${DICA_BASE}melanoom-dmtr/`,
        },
        //getTenantLink("dica"),
    ],
    Dnma: [
        {
            text: "DNMA",
            link: `${DICA_BASE}neuromodulatie-dnma/`,
        },
        //getTenantLink("dica"),
    ],
    Dpa: [
        {
            text: "DPA",
            link: `${DICA_BASE}prostaat-dpa/`,
        },
        //getTenantLink("dica"),
    ],
    Dpaa: [
        {
            text: "DPAA",
            link: `${DICA_BASE}perioperatieve-anesthesiologische-zorg-dpaa/`,
        },
        //getTenantLink("dica"),
    ],
    Dpard: [
        {
            text: "DPARD",
            link: `${DICA_BASE}diabetes-dpard/`,
        },
        //getTenantLink("dica"),
    ],
    Dpca: [
        {
            text: "DPCA",
            link: `${DICA_BASE}alvleesklier-dpca/`,
        },
        //getTenantLink("dica"),
    ],
    Dppa: [
        {
            text: "DPPA",
            link: `${DICA_BASE}pijn-en-palliatieve-zorg-dppa/`,
        },
        //getTenantLink("dica"),
    ],
    Draim: [
        {
            text: "DRAIM",
            link: "https://www.internisten.nl/werken-als-internist/richtlijnenindicatoren/kwaliteitsregistratie-reglementen",
        },
        //getTenantLink("niv"),
    ],
    Dsaa: [
        {
            text: "DSAA",
            link: `${DICA_BASE}aorta-interventies-dsaa/`,
        },
        //getTenantLink("dica"),
    ],
    Duca: [
        {
            text: "DUCA",
            link: `${DICA_BASE}maag-en-slokdarmkanker-duca/`,
        },
        //getTenantLink("dica"),
    ],
    Epsa: [
        {
            text: "EPSA",
            link: `${DICA_BASE}kinderchirurgie-epsa/`,
        },
        //getTenantLink("dica"),
    ],
    Gli: [
        {
            text: "GLI",
            link: "https://www.loketgezondleven.nl/zorgstelsel/gecombineerde-leefstijlinterventie",
        },
        //getTenantLink("rivm"),
    ],
    Icra: [
        {
            text: "ICRA",
            link: `https://www.nshealth.ca/`,
        },
        //getTenantLink("dica"),
    ],
    Nbca: [
        {
            text: "NBCA",
            link: `${DICA_BASE}borstkanker-nbca/`,
        },
        //getTenantLink("dica"),
    ],
    NbcaR: [
        {
            text: "NBCA",
            link: `${DICA_BASE}borstkanker-nbca/`,
        },
        //getTenantLink("dica"),
    ],
    Pice: [getTenantLink("pice")],
    PiceW: [getTenantLink("pice")],
};
