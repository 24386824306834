export const DICA_REGISTRIES = [
    "Daci",
    "Dasa",
    "Dato",
    "Dbir",
    "Dcra",
    "DcraR",
    "Dgda",
    "Dgea",
    "Drce",
    "DgeaDrce",
    "Dgoa",
    "Dhba",
    "Dhfa",
    "Dhna",
    "Diha",
    "DlcaL",
    "DlcaR",
    "DlcaS",
    "DlcaSErats",
    "Dma",
    "Dmtr",
    "Dnma",
    "Dpa",
    "Dpaa",
    "Dpard",
    "Dpca",
    "Dppa",
    "Dsaa",
    "Duca",
    "Epsa",
    "Nbca",
    "NbcaR",
];
